import React, { Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Drawer, withStyles } from "@material-ui/core";
import { Route } from "react-router";
import compose from "recompose/compose";

import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  CreateButton,
  EditButton,
  useTranslate,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
} from "react-admin";

import { SystemRouteEnableButton } from "../../../components/system";
import SystemRouteCreate from "./SystemRouteCreate";
import SystemRouteEdit from "./SystemRouteEdit";

const styles = {
  drawerContent: {
    width: 300,
  },
};

const CustomIsTextField = ({ record, source }) => {
  const translate = useTranslate();
  let str = "";
  if (record && record[source] === 0) {
    str = <span className="badge">{translate("ra.sources.no")}</span>;
  } else if (record && record[source] === 1) {
    str = <span className="badge bg-green">{translate("ra.sources.yes")}</span>;
  }
  return str;
};

const OpertionButton = ({ basePath, record }) => {
  return (
    <TopToolbar style={{ justifyContent: "flex-start" }}>
      <EditButton label="ra.action.edit" basePath={basePath} record={record} />
      <SystemRouteEnableButton basePath={basePath} record={record} />
    </TopToolbar>
  );
};

const SystemRouteActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton label="ra.action.create" basePath={basePath} />
  </TopToolbar>
);

const SystemRouteFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="ra.sources.path"
      source="path"
      resettable="true"
      alwaysOn
    />
    <ReferenceInput
      label="ra.sources.parent"
      source="parentId"
      reference="system/route/parent"
      emptyText="ra.sources.all"
      alwaysOn
    >
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <SelectInput
      label="ra.sources.is_menu"
      source="isMenu"
      emptyText="ra.sources.all"
      choices={[
        { id: 0, name: "ra.sources.no" },
        { id: 1, name: "ra.sources.yes" },
      ]}
      alwaysOn
    />
  </Filter>
);

class SystemRouteList extends React.Component {
  render() {
    const { push, classes, ...props } = this.props;
    return (
      <Fragment>
        <List
          {...props}
          empty={false}
          bulkActionButtons={false}
          title="ra.menu.system_route"
          actions={<SystemRouteActions />}
          filters={<SystemRouteFilter />}
        >
          <Datagrid>
            <TextField source="id" label="ra.sources.id" sortable={false} />
            <TextField
              source="permissions"
              label="ra.sources.permissions_path"
              sortable={false}
            />
            <TextField
              source="parent"
              label="ra.sources.parent"
              sortable={false}
            />
            <TextField
              source="description"
              label="ra.sources.description"
              sortable={false}
            />
            <TextField
              source="order"
              label="ra.sources.order"
              sortable={false}
            />
            <CustomIsTextField
              source="isMenu"
              label="ra.sources.is_menu"
              sortable={false}
            />
            <CustomIsTextField
              source="isPermissions"
              label="ra.sources.is_permissions"
              sortable={false}
            />
            <OpertionButton />
          </Datagrid>
        </List>
        <Route path="/system/route/create">
          {({ match }) => (
            <Drawer open={!!match} onClose={this.handleClose} anchor="right">
              <SystemRouteCreate
                className={classes.drawerContent}
                onCancel={this.handleClose}
                {...props}
              />
            </Drawer>
          )}
        </Route>
        <Route path="/system/route/:id">
          {({ match }) => {
            const isMatch =
              match && match.params && match.params.id !== "create";

            return (
              <Drawer open={isMatch} onClose={this.handleClose} anchor="right">
                {isMatch ? (
                  <SystemRouteEdit
                    className={classes.drawerContent}
                    id={match.params.id}
                    onCancel={this.handleClose}
                    {...props}
                  />
                ) : (
                  <div className={classes.drawerContent} />
                )}
              </Drawer>
            );
          }}
        </Route>
      </Fragment>
    );
  }
  handleClose = () => {
    this.props.push("/system/route");
  };
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(SystemRouteList);

import React from "react";
import { Login, LoginForm } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  // main: { background: "#333" },
  avatar: {
    backgroundImage: "url(/monitor.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    height: 80,
  },
  icon: { display: "none" },
};

const CustomLoginForm = withStyles({
  button: { background: "#F15922" },
})(LoginForm);

const LoginPage = (props) => (
  <Login loginForm={<CustomLoginForm />} {...props} />
);

export default withStyles(styles)(LoginPage);

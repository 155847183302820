import React, { Fragment } from "react";
import { Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withStyles } from "@material-ui/core";
import compose from "recompose/compose";
import Select from "react-select";
import L from "leaflet"
// import TaichungCity from "../../../TaichungCity.json";
import Taiwan from "../../../Taiwan.json";
import { apiHost, headers, streamHost } from "../../../components/common"
import VideoPlayer from "../../../components/VideoPlayer";

const styles = {};

class MonitorMap extends React.Component {
  constructor() {
    super();
    this.state = {
      zoom: 8,
      data: [],
      city: "",
      area: "",
      position: [23.973875, 120.973881], // 臺灣中心
      showModal: false,
      videoUrl: '',
      videoTitle: '',
    }
  }

  componentDidMount() {
    document.addEventListener('showModalEvent', this.handleShowModalEvent);

    // 引入地圖
    this.map = L.map('map', {
      center: this.state.position,
      zoom: this.state.zoom
    });

    // 引入圖資
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.getAreaData(this.state.city, this.state.area);
  }

  componentDidUpdate() {
    this.map.eachLayer((layer) => {
      if (layer.dragging) {
        layer.remove();
      }
    });
    
    this.map.panTo([this.state.position[0], this.state.position[1]]);
    this.map.setView([this.state.position[0], this.state.position[1]], this.state.zoom);

    if (this.state.data.length > 0) {
      this.state.data.forEach((item) => {
        const popup = L.popup()
        .setContent(this.goToShow(item));

        const LeafIcon = L.Icon.extend({
          options: {
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]},
        });
        const icon = new LeafIcon({
          iconUrl: "/security-camera.svg",
        })
        new L.marker([item.lat, item.lon], { icon }).bindPopup(popup, { closeButton: false }).addTo(this.map);
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('showModalEvent', this.handleShowModalEvent);
  }

  getAreaData = (selectedCity, selectedArea) => {
    if (selectedCity && selectedArea) {
      // const url = `${apiHost}/api/monitor/suburb/${area}`;
      const url = `${apiHost}/api/monitor/area/${selectedCity}/${selectedArea}?company_id=${localStorage.getItem("companyId")}`;
      fetch(url, { method: "GET", headers: headers(true, true)})
      .then((rep) => rep.json())
      .then((rep) => {
        let lat = "";
        let lon = "";
        Taiwan.forEach((item) => {
          if (item.CityName === selectedCity) {
            item.AreaList.forEach((item) => {
              if (item.AreaName === selectedArea) {
                lat = item.Lat;
                lon = item.Lon
              }
            });
          }
        });
        this.setState({ data: rep });
        this.setState({ zoom: 14 });
        this.setState({ position: [lat, lon] });
      });
    }
  }

  goToShow = (monitor) => {
    const monitors = new Set();
    for(let i = 0; i < monitor.monitor.length; i++) {
      if (monitor.monitor[i].is_display) {
        monitors.add(`${streamHost}/${monitor.monitor[i].number}/index.m3u8`);
      }
    }
    
    return (
    `<div class="air_info">
      <div><button class="view" onclick="viewVideo('${[...monitors].join(",")}', '${monitor.station_name}');">檢視</button></div>
    </div>`);
  }

  handleShowModalEvent = (event) => {
    const { videoUrl, videoTitle } = event.detail;
    this.setState({ showModal: true, videoUrl, videoTitle });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal, videoUrl, videoTitle } = this.state;
    const videos = videoUrl.split(",");

    const cityOptions = () => {
      return Taiwan.map((city) => {
        return { value: city.CityName, label: city.CityName }
      });
    }

    const areaOptions = () => {
      const selectedCity = this.state.city;

      let areas = [];
      Taiwan.forEach((city) => {
        if (selectedCity === city.CityName) {
          areas = city.AreaList;
        }
      });
      return areas.map((area) => {
        return { value: area.AreaName, label: area.AreaName }
      });
    }

    const handleCityInputChange = (selectedCityOption) => {
      this.setState({ city: selectedCityOption.value });
    };

    const handleAreaInputChange = (selectedAreaOption) => {
      this.setState({ area: selectedAreaOption.value });
      this.getAreaData(this.state.city, selectedAreaOption.value);
    };

    return (
      <Fragment>
        <div id="map"></div>
        <div className="select-box">
          <Select
            className="city-select"
            isSearchable={true}
            defaultValue={{ label: "請選擇縣市", value: "" }}
            options={cityOptions()}
            onChange={handleCityInputChange}
          />
          <Select
            className="area-select"
            isSearchable={true}
            defaultValue={{ label: "請選擇鄉區", value: "" }}
            options={areaOptions()}
            onChange={handleAreaInputChange}
          />
        </div>
        <Modal id="videoModal" show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
              <Modal.Title>{videoTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {videos.map((video) => <VideoPlayer src={video}/>)}
          </Modal.Body>
        </Modal> 
      </Fragment>
    );
  }
}

export default compose(
  connect(undefined, { push }),
  withStyles(styles)
)(MonitorMap);
// import * as React from "react";
// import { Card, CardHeader } from "@material-ui/core";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// export default () => (
//   <Card>
//     <CardHeader title="Welcome to the Admin" />
//   </Card>
// );

export default () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/monitor');
  }, [history]);

  return null;

  // return (
  //   <Card>
  //     <CardHeader title="Welcome to the Admin" />
  //   </Card>
  // )
};


import decodeJwt from "jwt-decode";
import { apiHost, headers } from "./components/common";

export default {
  login: async ({ username, password }) => {
    const request = new Request(`${apiHost}/api/auth/login`, {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers(headers(true, false)),
    });
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const { data } = await response.json();
    const decodedToken = decodeJwt(data);
    localStorage.setItem("userId", decodedToken.user_id);
    localStorage.setItem("token", data);
    localStorage.setItem("permissions", decodedToken.permissions);
    localStorage.setItem("companyId", decodedToken.company_id);
  },
  logout: () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("companyId");
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("token")
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const permissions = localStorage.getItem("permissions");
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
};
